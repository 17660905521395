<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
      class="pl-0 pr-0 pt-0"
      style="max-width: 600px;"
    >
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text class="pt-1 pb-1">
              <div class="d-flex justify-space-between align-center">
                <span>
                  Promotor: {{ revenda.parent_name }}
                </span>

                <v-btn
                  text
                  @click="voltar()"
                >
                  VOLTAR
                </v-btn>

              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-card 
            :color="variables.colorPrimary"
            elevation="3"
            rounded="1"
            class="box-saldo"
            @click="openModalTransmissao()"
            dark
          >
            <v-card-title
              class="box-saldo-titulo"
            >
              Revenda {{ revenda.cod }}
            </v-card-title>

            <v-card-text
              class="box-saldo__content"
            >
              <span>Vendas do dia</span>
              <span class="date">{{ this.date | datas('DD/MM/YYYY') }}</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ revenda.vendas | currency }}</strong>
              </v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" class="pt-0 pr-1">
          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo"
            
          >
            <v-card-text
              class="box-saldo__content"
            >
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ (parseFloat(revenda.vendas_mes) / 30) | currency }}</strong>
              </v-skeleton-loader>
              <span>Media Mensal</span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" class="pt-0 pl-1">
          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
              >
                <strong>{{ (parseFloat(revenda.vendas_semana) / 7) | currency }}</strong>
              </v-skeleton-loader>
              <span>Media Semanal</span>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- <v-col cols="6" class="pt-0 pr-1">
          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Saldo Anterior</span>
              <strong>{{ revenda.deve_ant | currency }}</strong>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" class="pt-0 pl-1">
          <v-card 
            :color="variables.colorPrimary"
            elevation="3"
            rounded="1"
            class="box-saldo"
            @click="openModalPremiosPagos()"
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span>Movimento</span>
              <strong>{{ revenda.movimento | currency }}</strong>
            </v-card-text>
          </v-card>
        </v-col> -->


        <v-col cols="12">
          <v-expansion-panels v-model="isExpanded">
            <v-expansion-panel  @click="carregarMaisInformacoes">
              <v-expansion-panel-header class="pt-2 pb-2">
                <v-row>
                  <v-col>
                    Mais informações +
                  </v-col>
                </v-row>
                <!-- {{ date | datas('DD/MM/YYYY') }} - {{ entityStart.value }} - {{ entityEnd.value }} -->
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container 
                  class="text-center"
                  v-if="isLoading"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  />
                </v-container>
                <tableHistoricoVendas :dataTable="historicoVendas" v-else />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <ModalTransmissao />
    <ModalJogosWinners />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import Events from '@/core/service/events'

export default {
  name: 'Cadastro',
  components: {
    // autoCompleteEntities: () => import('../../components/autoCompleteEntities/gecom'),
    ModalTransmissao: () => import('../../components/modalTransmissoes'),
    tableHistoricoVendas: () => import('../../components/tableHistoricoVendas'),
    ModalJogosWinners: () => import('@/views/components/tabelaHistoricoVendas/components/modalJogosWinners'),
  },
  data: () => ({
    date: '',
    isExpanded: undefined,
    isLoading: false,
    loading: false
  }),

  watch: {

  },

  mounted () {
    if (this.$route.params.entity) {

      const entityId = this.$route.params.entity.entity_id
      this.date = this.$route.params.date

      this.loading = true
      this.setDataRevenda({ entityId, date: this.date })
        .finally(() => this.loading = false)
    } else {
      this.$router.push({ name: 'gecom-mobile' })
    }

  },

  computed: {
    ...mapGetters('relatorioDadosRevendaGecom', ['revenda', 'listaItens', 'configGecom', 'totais', 'historicoVendas']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('credenciador', ['item']),
    variables: () => variables,
  },

  methods: {
    ...mapActions('relatorioDadosRevendaGecom', ['setDataRevenda', 'getHistoricoVendas', 'clearItens']),
    // ...mapActions('credenciador', {
    //   getPromotor: 'getItens'
    // }),

    openModalTransmissao () {
       const data = {
        entityId: this.revenda.entity_id,
        entity_cod: this.revenda.cod,
        dtStart: this.date,
        dtEnd: this.date,
        hasMonthOrDate: 1
      }

      Events.$emit('modal::historicoVendas::transmissoes', data)
    },

    openModalPremiosPagos () {
       const entity = {
        entity_id: this.revenda.entity_id,
        entity_cod: this.revenda.entity_cod
      }

      Events.$emit('modal::historicoVendas::jogosWinners', { data: this.date, entity, hasMonthOrDate: 1 })
    },

    carregarMaisInformacoes() {
      this.isLoading = true
      if (typeof this.isExpanded === 'undefined') {
        this.getHistoricoVendas({ date:this.date, entityId: this.revenda.entity_id })
          .finally(() => this.isLoading = false)
      }
    },

    voltar () {
      const parentPathArray = this.revenda.parent_path.split('.')
      parentPathArray.pop()
      
      this.$router.push({ name: 'gecom-mobile', params: { parentPath: parentPathArray.join('.') } })
    }
  },

  beforeDestroy () {}
}
</script>

<style lang="scss" scoped>
  .box-saldo {
    color: #FFF;
    &-titulo {
      text-align: center;
      font-size: .9rem;
      width: 100%;
      display: block;
      padding-top: .4rem;
    }

    &__content {
      text-align: center;
     span {
       color: #Fff;
       display: block;
     }

     strong {
       color: #Fff;
       font-size: 1.3rem;
     }
    }

    .date {
      font-size: 12px;
      line-height: 1;
      margin-bottom: 10px;
    }
  }
</style>>
